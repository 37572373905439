<template>
<div class="tw-w-full tw-flex-col lg:tw-px-32 tw-px-5">
<h6 class="community tw-mt-10 lg:tw-hidden">Community</h6>
  <h6 class="community tw-mt-14">Join Our  Community</h6>
  <h6 class="desc tw-mt-8">Experience a wealth of Knowledge and gain more insights from our Forums.
    Join our  community to get access to more utilities</h6>

  <v-card flat class="card tw-mt-20 tw-py-10 lg:tw-px-14 tw-px-5">
    <h6 class="card-header">Nomadicpod’s Developer Community</h6>
    <div class="card-inner tw-mt-5 tw-p-3">
     <h6 class="link tw-cursor-pointer">Coming Soon</h6>
    </div>
  </v-card>
</div>
</template>

<script>
export default {
  name: "DeveloperCommunityView"
}
</script>

<style scoped lang="scss">


.desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(0, 17, 39, 0.64);
  width: 50%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.card{
  background: rgba(253, 255, 252, 0.06);
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  min-height: 179px;
  width: 100%;
}

.card-inner{
  background: #FDFFFC;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
  min-height: 89px;

}

.card-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(29, 38, 45, 0.83);
}
.link{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #004AAD;
  flex-wrap: wrap;
  flex-direction: row;
}

</style>